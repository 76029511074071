<template>
  <div
    class="wrapper"
    :class="
      $route.path.includes('/deal/') ||
      $route.path.includes('/angebot/') ||
      $route.path.includes('/aanbieding/')
        ? 'deal-page'
        : ''
    "
  >
    <!-- Superbanner Portal -->
    <portal-target name="superbanner" />

    <!-- Navbar -->
    <Navbar v-if="!isAppView && !$route.path.includes('/5vorflug-newsletter-anmeldung-bestaetigung')" />

    <!-- <HgFTIInfoBanner
      v-if="$i18n.locale == 'de'"
      link="https://www.urlaubsguru.de/reisemagazin/fti-insolvenz/"
    ></HgFTIInfoBanner> -->

    <!-- Search Tabs -->
    <intersect @enter="enterMethod" @leave="leaveMethod">
      <section v-if="isSearchRoute" id="search" class="search">
        <keep-alive>
          <SearchTabs
            :show-moving-badge="showMovingBadge"
            class="ads-spacing"
          />
        </keep-alive>
      </section>
    </intersect>

    <!-- Main -->
    <div class="main-content columns">
      <!-- Left Column -->
      <div class="column left-gutter" />

      <!-- Main Column -->
      <div class="column main-column">
        <nuxt />
      </div>
    </div>

    <!-- Footer -->
    <HFooter v-if="!$route.path.includes('/5vorflug-newsletter-anmeldung-bestaetigung')" />

    <!-- Auth Modal -->
    <AuthModal />

    <!-- Share Modal -->
    <ShareModal />

    <!-- External Script Loading -->
    <template
      v-if="['de', 'at', 'ch', 'es'].includes($i18n.locale) && !isAppView"
    >
      <client-only>
        <!-- Adup Loader  -->
        <template v-if="allowAdup && stroeerLoaded">
          <AdupLoader />
        </template>
      </client-only>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';
import * as crypto from 'crypto';
import allKameleoonFeatureVariablesQuery from '@/apollo/queries/kameleoon/allKameleoonFeatureVariables';
import KameleoonMixin from '../mixins/KameleoonMixin';

export default {
  middleware: ['isAppView'],
  mixins: [KameleoonMixin],
  components: {
    Navbar: () => import('@/components/partials/Navbar.vue'),
    SearchTabs: () => import('@/components/search/SearchTabs'),
    HFooter: () => import('@/components/partials/Footer.vue'),
    AdupLoader: () => import('@/components/partials/AdupLoader.vue'),
    ShareModal: () => import('@/components/modals/ShareModal'),
    AuthModal: () => import('@/components/modals/AuthModal')
  },
  data: () => ({
    searchRoutes: [],
    showMovingBadge: false,
    jsonLD: {},
    hrefLangTags: {
      '/': [
        {
          locale: 'de',
          hreflang: 'de-DE'
        },
        {
          locale: 'ch',
          hreflang: 'de-CH'
        },
        {
          locale: 'at',
          hreflang: 'de-AT'
        },
        {
          locale: 'nl',
          hreflang: 'nl'
        },
        {
          locale: 'es',
          hreflang: 'es-ES'
        }
      ],
      '/suchen-und-buchen/': [
        {
          locale: 'de',
          hreflang: 'de-DE'
        },
        {
          locale: 'ch',
          hreflang: 'de-CH'
        },
        {
          locale: 'at',
          hreflang: 'de-AT'
        }
      ]
    },
    // EasterEgg-Data
    snowConf: {
      windPower: 2,
      speed: 2,
      count: 25,
      size: 15,
      opacity: 1,
      images: ['/assets/icons8-winter-50.png']
    },
    showEasterEgg: false
  }),
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      localeDomains: 'getLocaleDomains'
    }),
    ...mapState({
      stroeerLoaded: (state) => state.ads.stroeerLoaded,
      allowAdup: (state) => state.ads.allowAdup,
      showAuthModal: (state) => state.showAuthModal,
      showShareModal: (state) => state.showShareModal,
      selectedDeal: (state) => state.selectedDeal,
      isMobile: (state) => state.isMobile,
      isAppView: (state) => state.isAppView,
      isSearchRoute: (state) => state.isSearchRoute,
      currentPosition: (state) => state.currentPosition
    })
  },
  watch: {
    $route() {
      this.checkForSearchRoute();
      this.$store.commit('setCurrentPosition', {
        position: window.scrollY,
        path: this.$route.path
      });
      this.checkViewport();
    }
  },
  created() {
    // Routes on which to show search-tabs
    this.searchRoutes = [
      this.localePath('/'),
      this.localePath('partners'),
      this.localePath('deals'),
      this.localePath('hotd')
    ];
    this.checkForSearchRoute(); //
    this.jsonLD = this.$i18n.t('jsonLD'); // Schema.org on create
  },

  async fetch() {
    await this.loadKameleoon();
  },

  mounted() {
    const { testVariant } = this.$route.query;
    if (testVariant) {
      const exp = this.$cookies.get('exp').split('.');

      if (exp[1] != testVariant) {
        exp[1] = testVariant;

        // Set Cookie
        this.$cookies.set('exp', exp.join('.'), {
          path: '/',
          maxAge: 60 * 60 * 24 * 14
        });

        // refresh Page to load new variant
        setTimeout(() => {
          document.location.reload();
        }, 250);
      }
    }
    window.addEventListener('resize', debounce(this.eventResize, 300), false);
    this.eventResize();
    this.$store.commit('setCurrentPosition', {
      position: window.scrollY,
      path: this.$route.path
    });

    window.addEventListener('beforeinstallprompt', (event) =>
      event.preventDefault()
    );

    if (this.engineCode != null && this.engineCode.length > 0) {
      this.addKameleoonEngineCode(this.engineCode);
    }
  },

  beforeDestroy() {
    window.removeEventListener(
      'resize',
      debounce(this.eventResize, 300),
      false
    );
  },

  methods: {
    async loadKameleoon() {
      // Get the visitor code from the cookies
      let visitorCode = this.$cookies.get('KameleoonVisitorCode');

      if (visitorCode == null) {
        // Generate a new visitor code & save it as a cookie
        visitorCode = crypto.randomUUID();

        this.$cookies.set('KameleoonVisitorCode', visitorCode, {
          path: '/',
          maxAge: 60 * 60 * 24 * 14 // 2 weeks
        });
      }

      let result = await this.$apollo
        .query({
          query: allKameleoonFeatureVariablesQuery,
          variables: {
            visitorCode
          }
        })
        .catch((error) => {
          console.error(error);
        });

      if (result == null || result.data == null) return;

      this.engineCode = result.data.kameleoonEngineCode;
    },
    addKameleoonEngineCode(engineCode) {
      const script = document.createElement('script');
      script.textContent = engineCode;
      document.body.appendChild(script);
    },
    checkViewport() {
      // Is the Current Route in the currentArray?
      const previous = this.currentPosition.find(
        (el) => el.path === this.$route.path
      );

      if (previous) {
        setTimeout(() => {
          // Jump to the previous position after 1.5s (Timer to be sure Document & all Querries are loaded )
          document.documentElement.scrollTop = previous.position;
        }, 1500);
      }
    },
    /*
    keyUpHandler(e) {
      // EasterEgg
      if (window && e.target.parentElement.className === 'hg-header-headline')
        this.showEasterEgg = true;
    },
    */
    leaveMethod() {
      this.showMovingBadge = true;
    },
    enterMethod() {
      this.showMovingBadge = false;
    },
    eventResize() {
      if (!window) {
        return;
      }
      const width = window.innerWidth;
      this.$store.commit('setIsMobile', width < 768);
      this.$store.commit('setCurrentScreenSize', width);
    },
    checkForSearchRoute() {
      if (this.searchRoutes.includes(this.$route.path)) {
        this.$store.commit('setIsSearchRoute', true);
      } else {
        this.$store.commit('setIsSearchRoute', false);
      }
    },
    addHrefLangTags(domain) {
      let linkArray = [];
      if (
        !this.$route.path.includes('/deal/') &&
        !this.$route.path.includes('/angebot/') &&
        !this.$route.path.includes('/aanbieding/')
      ) {
        linkArray = [
          {
            rel: 'alternate',
            hreflang: 'x-default',
            href: 'https://www.urlaubsguru.de' + this.$route.path
          }
        ];
      }

      if (this.hrefLangTags[this.$route.path]) {
        this.hrefLangTags[this.$route.path].forEach((element) => {
          const hrefLang = element.hreflang;
          const tempObj = {
            rel: 'alternate',
            hreflang: hrefLang,
            'data-hid': 'alternate-hreflang-' + hrefLang,
            href:
              'https://' + this.localeDomains[element.locale] + this.$route.path
          };

          linkArray.push(tempObj);
        });
      }

      return linkArray;
    }
  },
  head() {
    const domain = this.localeDomains[this.$i18n.locale];
    const absolutePath = `https://${domain}${this.$route.path}`;

    const canonical = [
      {
        rel: 'canonical',
        href:
          process.env.NODE_ENV !== 'development'
            ? absolutePath
            : this.$route.path
      }
    ];

    const hrefLangTags = this.addHrefLangTags(domain);
    let link = canonical.concat(hrefLangTags);
    let ogImageLink = ['de', 'at'].includes(this.$i18n.locale)
      ? '/assets/images/og/ug.jpg'
      : '/assets/images/og/hg.jpg';

    if (this.$i18n.locale !== 'de' && this.$i18n.locale !== 'at') {
      link = link.concat(
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: '/assets/favicon_hg.ico'
        },
        {
          rel: 'shortcut icon',
          href: '/assets/icon_hg_64.png'
        },
        {
          rel: 'apple-touch-icon',
          href: '/assets/icon_hg_512.png',
          sizes: '512x512'
        }
      );
    } else {
      link = link.concat(
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: '/assets/favicon.ico'
        },
        {
          rel: 'shortcut icon',
          href: '/assets/icon_ug_64.png'
        },
        {
          rel: 'apple-touch-icon',
          href: '/assets/icon_ug_512.png',
          sizes: '512x512'
        }
      );
    }

    // Manifest
    if (this.$i18n.locale == 'nl') {
      link = link.concat({
        rel: 'manifest',
        href: '/manifest.nl.json'
      });
    } else if (this.$i18n.locale == 'es') {
      link = link.concat({
        rel: 'manifest',
        href: '/manifest.es.json'
      });
    } else if (this.$i18n.locale == 'at') {
      link = link.concat({
        rel: 'manifest',
        href: '/manifest.at.json'
      });
    } else if (this.$i18n.locale == 'ch') {
      link = link.concat({
        rel: 'manifest',
        href: '/manifest.ch.json'
      });
    } else {
      link = link.concat({
        rel: 'manifest',
        href: '/manifest.json'
      });
    }

    return {
      htmlAttrs: {
        lang:
          this.$i18n.locale === 'at' || this.$i18n.locale === 'ch'
            ? 'de'
            : this.$i18n.locale
      },
      title: this.$i18n.t('seo.home.title'),
      description: this.$i18n.t('seo.home.description'),
      meta: [
        { hid: 'robots', name: 'robots', content: this.$config.ROBOTS },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          hid: 'og:url',
          name: 'og:url',
          content: absolutePath
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$i18n.t('seo.home.title')
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$i18n.t('seo.home.description')
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: this.$i18n.t('holidayguru')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: ogImageLink
        },
        {
          hid: 'og:type',
          name: 'og:type',
          content: 'website'
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: this.$i18n.locale
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_card'
        },
        {
          hid: 'twitter:domain',
          property: 'twitter:domain',
          content: domain
        },
        {
          hid: 'twitter:url',
          property: 'twitter:url',
          content: absolutePath
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: this.$i18n.t('seo.home.title')
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.$i18n.t('seo.home.description')
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: ogImageLink
        },
        {
          hid: 'theme-color',
          name: 'theme-color',
          content: '#00D5E0'
        }
      ],
      link,
      __dangerouslyDisableSanitizersByTagID: {
        jsonld: ['innerHTML'],
        'optimize-anti-flicker': ['innerHTML']
      },
      script: [
        {
          hid: 'jsonld',
          vmid: 'jsonld',
          innerHTML: JSON.stringify(this.jsonLD),
          type: 'application/ld+json'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.test {
  display: none;
}

.main-content {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 !important;
}

.main-column {
  width: 100%;
  max-width: 100vw;
  padding: 0 !important;
}

.left-gutter {
  display: none;
}

.right-gutter {
  display: none;
}
</style>

<style lang="scss">
.main-column section {
  background: none;
}

.more-link-slide {
  border-radius: 0.5rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.more-link-slide a {
  color: $teal-600;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0;
  border-radius: 2rem;
  height: 2rem;
  transform: translateX(-0.5rem);
  transition: all 0.3s;

  &:hover {
    background-color: $teal-500;
    color: $teal-900;
  }
}

.more-link-slide a span:first-child {
  margin: 0 0.5rem;
}

.more-link-slide a .icon {
  background-color: $teal-500;
  color: $teal-900;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
</style>
