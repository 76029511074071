import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _18c30d22 = () => interopDefault(import('../pages/404/index.vue' /* webpackChunkName: "pages/404/index" */))
const _c8c48582 = () => interopDefault(import('../pages/5vorflug-newsletter-anmeldung-bestaetigung.vue' /* webpackChunkName: "pages/5vorflug-newsletter-anmeldung-bestaetigung" */))
const _86b3f48a = () => interopDefault(import('../pages/deals.vue' /* webpackChunkName: "pages/deals" */))
const _4876c7a4 = () => interopDefault(import('../pages/auth/activate.vue' /* webpackChunkName: "pages/auth/activate" */))
const _6f03df27 = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _4c272b84 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _71f4f9a2 = () => interopDefault(import('../pages/preferences/index.vue' /* webpackChunkName: "pages/preferences/index" */))
const _3efd5d31 = () => interopDefault(import('../pages/bookmarks.vue' /* webpackChunkName: "pages/bookmarks" */))
const _b3199b9a = () => interopDefault(import('../pages/gutscheinauszahlung/index.vue' /* webpackChunkName: "pages/gutscheinauszahlung/index" */))
const _62d90433 = () => interopDefault(import('../pages/hotd.vue' /* webpackChunkName: "pages/hotd" */))
const _2a1a3949 = () => interopDefault(import('../pages/kortinguitbetaling/index.vue' /* webpackChunkName: "pages/kortinguitbetaling/index" */))
const _9e9c33f6 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _b7bb9c10 = () => interopDefault(import('../pages/newsletter/index.vue' /* webpackChunkName: "pages/newsletter/index" */))
const _021c12cc = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _35050448 = () => interopDefault(import('../pages/preferences/center.vue' /* webpackChunkName: "pages/preferences/center" */))
const _859ec444 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _b527b6d2 = () => interopDefault(import('../pages/reise-agent/index.vue' /* webpackChunkName: "pages/reise-agent/index" */))
const _476f6754 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _7add72c9 = () => interopDefault(import('../pages/auth/participation-confirmation-raffle.vue' /* webpackChunkName: "pages/auth/participation-confirmation-raffle" */))
const _e57807d2 = () => interopDefault(import('../pages/partner/ameropa/index.vue' /* webpackChunkName: "pages/partner/ameropa/index" */))
const _2de7c98e = () => interopDefault(import('../pages/partner/ameropa/amsterdam-deal.vue' /* webpackChunkName: "pages/partner/ameropa/amsterdam-deal" */))
const _7fb140e1 = () => interopDefault(import('../pages/partner/ameropa/berlin-deal-2.vue' /* webpackChunkName: "pages/partner/ameropa/berlin-deal-2" */))
const _644d0ebc = () => interopDefault(import('../pages/partner/ameropa/berlin-deal.vue' /* webpackChunkName: "pages/partner/ameropa/berlin-deal" */))
const _262e5498 = () => interopDefault(import('../pages/partner/ameropa/dresden-deal-2.vue' /* webpackChunkName: "pages/partner/ameropa/dresden-deal-2" */))
const _a8ccb79a = () => interopDefault(import('../pages/partner/ameropa/dresden-deal.vue' /* webpackChunkName: "pages/partner/ameropa/dresden-deal" */))
const _4e9d9ba6 = () => interopDefault(import('../pages/partner/ameropa/hamburg-deal-2.vue' /* webpackChunkName: "pages/partner/ameropa/hamburg-deal-2" */))
const _521e39f0 = () => interopDefault(import('../pages/partner/ameropa/hamburg-deal.vue' /* webpackChunkName: "pages/partner/ameropa/hamburg-deal" */))
const _fdb8bd7e = () => interopDefault(import('../pages/partner/ameropa/koeln-deal.vue' /* webpackChunkName: "pages/partner/ameropa/koeln-deal" */))
const _32c72a2c = () => interopDefault(import('../pages/partner/ameropa/leipzig-deal.vue' /* webpackChunkName: "pages/partner/ameropa/leipzig-deal" */))
const _fa36a4f8 = () => interopDefault(import('../pages/partner/ameropa/muenchen-deal-2.vue' /* webpackChunkName: "pages/partner/ameropa/muenchen-deal-2" */))
const _4da9481f = () => interopDefault(import('../pages/partner/ameropa/muenchen-deal.vue' /* webpackChunkName: "pages/partner/ameropa/muenchen-deal" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9cb39f34 = () => interopDefault(import('../pages/deal/accommodation/_slug.vue' /* webpackChunkName: "pages/deal/accommodation/_slug" */))
const _ae1892ec = () => interopDefault(import('../pages/deal/amadeus-package-tour/_slug.vue' /* webpackChunkName: "pages/deal/amadeus-package-tour/_slug" */))
const _62fdb242 = () => interopDefault(import('../pages/deal/basic/_slug.vue' /* webpackChunkName: "pages/deal/basic/_slug" */))
const _1e8981b1 = () => interopDefault(import('../pages/deal/package-tour/_slug.vue' /* webpackChunkName: "pages/deal/package-tour/_slug" */))
const _425f0226 = () => interopDefault(import('../pages/deal/travel-basic/_slug.vue' /* webpackChunkName: "pages/deal/travel-basic/_slug" */))
const _79af4f4e = () => interopDefault(import('../pages/deal/travel-combination/_slug.vue' /* webpackChunkName: "pages/deal/travel-combination/_slug" */))
const _aa9a86bc = () => interopDefault(import('../pages/deal/flight/_slug.vue' /* webpackChunkName: "pages/deal/flight/_slug" */))
const _958c87ee = () => interopDefault(import('../pages/tag/_slug.vue' /* webpackChunkName: "pages/tag/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404/",
    component: _18c30d22,
    pathToRegexpOptions: {"strict":true},
    name: "404___de"
  }, {
    path: "/404/",
    component: _18c30d22,
    pathToRegexpOptions: {"strict":true},
    name: "404___ch"
  }, {
    path: "/404/",
    component: _18c30d22,
    pathToRegexpOptions: {"strict":true},
    name: "404___at"
  }, {
    path: "/404/",
    component: _18c30d22,
    pathToRegexpOptions: {"strict":true},
    name: "404___nl"
  }, {
    path: "/404/",
    component: _18c30d22,
    pathToRegexpOptions: {"strict":true},
    name: "404___es"
  }, {
    path: "/5vorflug-newsletter-anmeldung-bestaetigung/",
    component: _c8c48582,
    pathToRegexpOptions: {"strict":true},
    name: "5vorflug-newsletter-anmeldung-bestaetigung___de"
  }, {
    path: "/5vorflug-newsletter-anmeldung-bestaetigung/",
    component: _c8c48582,
    pathToRegexpOptions: {"strict":true},
    name: "5vorflug-newsletter-anmeldung-bestaetigung___ch"
  }, {
    path: "/5vorflug-newsletter-anmeldung-bestaetigung/",
    component: _c8c48582,
    pathToRegexpOptions: {"strict":true},
    name: "5vorflug-newsletter-anmeldung-bestaetigung___at"
  }, {
    path: "/5vorflug-newsletter-anmeldung-bestaetigung/",
    component: _c8c48582,
    pathToRegexpOptions: {"strict":true},
    name: "5vorflug-newsletter-anmeldung-bestaetigung___nl"
  }, {
    path: "/5vorflug-newsletter-anmeldung-bestaetigung/",
    component: _c8c48582,
    pathToRegexpOptions: {"strict":true},
    name: "5vorflug-newsletter-anmeldung-bestaetigung___es"
  }, {
    path: "/aanbiedingen/",
    component: _86b3f48a,
    pathToRegexpOptions: {"strict":true},
    name: "deals___nl"
  }, {
    path: "/activate/",
    component: _4876c7a4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-activate___de"
  }, {
    path: "/activate/",
    component: _4876c7a4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-activate___ch"
  }, {
    path: "/activate/",
    component: _4876c7a4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-activate___at"
  }, {
    path: "/activate/",
    component: _4876c7a4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-activate___nl"
  }, {
    path: "/activate/",
    component: _4876c7a4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-activate___es"
  }, {
    path: "/angebote/",
    component: _86b3f48a,
    pathToRegexpOptions: {"strict":true},
    name: "deals___de"
  }, {
    path: "/angebote/",
    component: _86b3f48a,
    pathToRegexpOptions: {"strict":true},
    name: "deals___ch"
  }, {
    path: "/angebote/",
    component: _86b3f48a,
    pathToRegexpOptions: {"strict":true},
    name: "deals___at"
  }, {
    path: "/busca-y-reserva/",
    component: _6f03df27,
    pathToRegexpOptions: {"strict":true},
    name: "partners___es"
  }, {
    path: "/deals/",
    component: _86b3f48a,
    pathToRegexpOptions: {"strict":true},
    name: "deals___es"
  }, {
    path: "/einloggen/",
    component: _4c272b84,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___de"
  }, {
    path: "/einloggen/",
    component: _4c272b84,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___ch"
  }, {
    path: "/einloggen/",
    component: _4c272b84,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___at"
  }, {
    path: "/einstellungen/",
    component: _71f4f9a2,
    pathToRegexpOptions: {"strict":true},
    name: "preferences___de"
  }, {
    path: "/einstellungen/",
    component: _71f4f9a2,
    pathToRegexpOptions: {"strict":true},
    name: "preferences___ch"
  }, {
    path: "/einstellungen/",
    component: _71f4f9a2,
    pathToRegexpOptions: {"strict":true},
    name: "preferences___at"
  }, {
    path: "/favorieten/",
    component: _3efd5d31,
    pathToRegexpOptions: {"strict":true},
    name: "bookmarks___nl"
  }, {
    path: "/gutscheinauszahlung/",
    component: _b3199b9a,
    pathToRegexpOptions: {"strict":true},
    name: "gutscheinauszahlung___de"
  }, {
    path: "/gutscheinauszahlung/",
    component: _b3199b9a,
    pathToRegexpOptions: {"strict":true},
    name: "gutscheinauszahlung___ch"
  }, {
    path: "/gutscheinauszahlung/",
    component: _b3199b9a,
    pathToRegexpOptions: {"strict":true},
    name: "gutscheinauszahlung___at"
  }, {
    path: "/gutscheinauszahlung/",
    component: _b3199b9a,
    pathToRegexpOptions: {"strict":true},
    name: "gutscheinauszahlung___nl"
  }, {
    path: "/gutscheinauszahlung/",
    component: _b3199b9a,
    pathToRegexpOptions: {"strict":true},
    name: "gutscheinauszahlung___es"
  }, {
    path: "/hotel-des-tages/",
    component: _62d90433,
    pathToRegexpOptions: {"strict":true},
    name: "hotd___de"
  }, {
    path: "/hotel-des-tages/",
    component: _62d90433,
    pathToRegexpOptions: {"strict":true},
    name: "hotd___ch"
  }, {
    path: "/hotel-des-tages/",
    component: _62d90433,
    pathToRegexpOptions: {"strict":true},
    name: "hotd___at"
  }, {
    path: "/hotel-des-tages/",
    component: _62d90433,
    pathToRegexpOptions: {"strict":true},
    name: "hotd___nl"
  }, {
    path: "/hotel-des-tages/",
    component: _62d90433,
    pathToRegexpOptions: {"strict":true},
    name: "hotd___es"
  }, {
    path: "/inloggen/",
    component: _4c272b84,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___nl"
  }, {
    path: "/kortinguitbetaling/",
    component: _2a1a3949,
    pathToRegexpOptions: {"strict":true},
    name: "kortinguitbetaling___de"
  }, {
    path: "/kortinguitbetaling/",
    component: _2a1a3949,
    pathToRegexpOptions: {"strict":true},
    name: "kortinguitbetaling___ch"
  }, {
    path: "/kortinguitbetaling/",
    component: _2a1a3949,
    pathToRegexpOptions: {"strict":true},
    name: "kortinguitbetaling___at"
  }, {
    path: "/kortinguitbetaling/",
    component: _2a1a3949,
    pathToRegexpOptions: {"strict":true},
    name: "kortinguitbetaling___nl"
  }, {
    path: "/kortinguitbetaling/",
    component: _2a1a3949,
    pathToRegexpOptions: {"strict":true},
    name: "kortinguitbetaling___es"
  }, {
    path: "/login/",
    component: _4c272b84,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___es"
  }, {
    path: "/logout/",
    component: _9e9c33f6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-logout___de"
  }, {
    path: "/logout/",
    component: _9e9c33f6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-logout___ch"
  }, {
    path: "/logout/",
    component: _9e9c33f6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-logout___at"
  }, {
    path: "/logout/",
    component: _9e9c33f6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-logout___nl"
  }, {
    path: "/logout/",
    component: _9e9c33f6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-logout___es"
  }, {
    path: "/marcadores/",
    component: _3efd5d31,
    pathToRegexpOptions: {"strict":true},
    name: "bookmarks___es"
  }, {
    path: "/merkliste/",
    component: _3efd5d31,
    pathToRegexpOptions: {"strict":true},
    name: "bookmarks___de"
  }, {
    path: "/merkliste/",
    component: _3efd5d31,
    pathToRegexpOptions: {"strict":true},
    name: "bookmarks___ch"
  }, {
    path: "/merkliste/",
    component: _3efd5d31,
    pathToRegexpOptions: {"strict":true},
    name: "bookmarks___at"
  }, {
    path: "/newsletter/",
    component: _b7bb9c10,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter___de"
  }, {
    path: "/newsletter/",
    component: _b7bb9c10,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter___ch"
  }, {
    path: "/newsletter/",
    component: _b7bb9c10,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter___at"
  }, {
    path: "/newsletter/",
    component: _b7bb9c10,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter___nl"
  }, {
    path: "/newsletter/",
    component: _b7bb9c10,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter___es"
  }, {
    path: "/passwort-vergessen/",
    component: _021c12cc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password___de"
  }, {
    path: "/passwort-vergessen/",
    component: _021c12cc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password___ch"
  }, {
    path: "/passwort-vergessen/",
    component: _021c12cc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password___at"
  }, {
    path: "/preference-center/",
    component: _35050448,
    pathToRegexpOptions: {"strict":true},
    name: "preferences-center___de"
  }, {
    path: "/preference-center/",
    component: _35050448,
    pathToRegexpOptions: {"strict":true},
    name: "preferences-center___ch"
  }, {
    path: "/preference-center/",
    component: _35050448,
    pathToRegexpOptions: {"strict":true},
    name: "preferences-center___at"
  }, {
    path: "/preference-center/",
    component: _35050448,
    pathToRegexpOptions: {"strict":true},
    name: "preferences-center___nl"
  }, {
    path: "/preference-center/",
    component: _35050448,
    pathToRegexpOptions: {"strict":true},
    name: "preferences-center___es"
  }, {
    path: "/registreren/",
    component: _859ec444,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___nl"
  }, {
    path: "/registrieren/",
    component: _859ec444,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___de"
  }, {
    path: "/registrieren/",
    component: _859ec444,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___ch"
  }, {
    path: "/registrieren/",
    component: _859ec444,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___at"
  }, {
    path: "/registro/",
    component: _859ec444,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___es"
  }, {
    path: "/reise-agent/",
    component: _b527b6d2,
    pathToRegexpOptions: {"strict":true},
    name: "reise-agent___de"
  }, {
    path: "/reise-agent/",
    component: _b527b6d2,
    pathToRegexpOptions: {"strict":true},
    name: "reise-agent___ch"
  }, {
    path: "/reise-agent/",
    component: _b527b6d2,
    pathToRegexpOptions: {"strict":true},
    name: "reise-agent___at"
  }, {
    path: "/reise-agent/",
    component: _b527b6d2,
    pathToRegexpOptions: {"strict":true},
    name: "reise-agent___nl"
  }, {
    path: "/reise-agent/",
    component: _b527b6d2,
    pathToRegexpOptions: {"strict":true},
    name: "reise-agent___es"
  }, {
    path: "/reset-password/",
    component: _476f6754,
    pathToRegexpOptions: {"strict":true},
    name: "auth-reset-password___de"
  }, {
    path: "/reset-password/",
    component: _476f6754,
    pathToRegexpOptions: {"strict":true},
    name: "auth-reset-password___ch"
  }, {
    path: "/reset-password/",
    component: _476f6754,
    pathToRegexpOptions: {"strict":true},
    name: "auth-reset-password___at"
  }, {
    path: "/reset-password/",
    component: _476f6754,
    pathToRegexpOptions: {"strict":true},
    name: "auth-reset-password___nl"
  }, {
    path: "/restablecer-contrasena/",
    component: _021c12cc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password___es"
  }, {
    path: "/restablecer/",
    component: _476f6754,
    pathToRegexpOptions: {"strict":true},
    name: "auth-reset-password___es"
  }, {
    path: "/settings/",
    component: _71f4f9a2,
    pathToRegexpOptions: {"strict":true},
    name: "preferences___nl"
  }, {
    path: "/settings/",
    component: _71f4f9a2,
    pathToRegexpOptions: {"strict":true},
    name: "preferences___es"
  }, {
    path: "/suchen-und-buchen/",
    component: _6f03df27,
    pathToRegexpOptions: {"strict":true},
    name: "partners___de"
  }, {
    path: "/suchen-und-buchen/",
    component: _6f03df27,
    pathToRegexpOptions: {"strict":true},
    name: "partners___ch"
  }, {
    path: "/suchen-und-buchen/",
    component: _6f03df27,
    pathToRegexpOptions: {"strict":true},
    name: "partners___at"
  }, {
    path: "/teilnahmebestaetigung-gewinnspiel/",
    component: _7add72c9,
    pathToRegexpOptions: {"strict":true},
    name: "auth-participation-confirmation-raffle___de"
  }, {
    path: "/teilnahmebestaetigung-gewinnspiel/",
    component: _7add72c9,
    pathToRegexpOptions: {"strict":true},
    name: "auth-participation-confirmation-raffle___ch"
  }, {
    path: "/teilnahmebestaetigung-gewinnspiel/",
    component: _7add72c9,
    pathToRegexpOptions: {"strict":true},
    name: "auth-participation-confirmation-raffle___at"
  }, {
    path: "/teilnahmebestaetigung-gewinnspiel/",
    component: _7add72c9,
    pathToRegexpOptions: {"strict":true},
    name: "auth-participation-confirmation-raffle___nl"
  }, {
    path: "/teilnahmebestaetigung-gewinnspiel/",
    component: _7add72c9,
    pathToRegexpOptions: {"strict":true},
    name: "auth-participation-confirmation-raffle___es"
  }, {
    path: "/wachtwoord-vergeten/",
    component: _021c12cc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password___nl"
  }, {
    path: "/zoeken-en-boeken/",
    component: _6f03df27,
    pathToRegexpOptions: {"strict":true},
    name: "partners___nl"
  }, {
    path: "/partner/ameropa/",
    component: _e57807d2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa___de"
  }, {
    path: "/partner/ameropa/",
    component: _e57807d2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa___ch"
  }, {
    path: "/partner/ameropa/",
    component: _e57807d2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa___at"
  }, {
    path: "/partner/ameropa/",
    component: _e57807d2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa___nl"
  }, {
    path: "/partner/ameropa/",
    component: _e57807d2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa___es"
  }, {
    path: "/partner/ameropa/amsterdam-deal/",
    component: _2de7c98e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-amsterdam-deal___de"
  }, {
    path: "/partner/ameropa/amsterdam-deal/",
    component: _2de7c98e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-amsterdam-deal___ch"
  }, {
    path: "/partner/ameropa/amsterdam-deal/",
    component: _2de7c98e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-amsterdam-deal___at"
  }, {
    path: "/partner/ameropa/amsterdam-deal/",
    component: _2de7c98e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-amsterdam-deal___nl"
  }, {
    path: "/partner/ameropa/amsterdam-deal/",
    component: _2de7c98e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-amsterdam-deal___es"
  }, {
    path: "/partner/ameropa/berlin-deal-2/",
    component: _7fb140e1,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-berlin-deal-2___de"
  }, {
    path: "/partner/ameropa/berlin-deal-2/",
    component: _7fb140e1,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-berlin-deal-2___ch"
  }, {
    path: "/partner/ameropa/berlin-deal-2/",
    component: _7fb140e1,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-berlin-deal-2___at"
  }, {
    path: "/partner/ameropa/berlin-deal-2/",
    component: _7fb140e1,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-berlin-deal-2___nl"
  }, {
    path: "/partner/ameropa/berlin-deal-2/",
    component: _7fb140e1,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-berlin-deal-2___es"
  }, {
    path: "/partner/ameropa/berlin-deal/",
    component: _644d0ebc,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-berlin-deal___de"
  }, {
    path: "/partner/ameropa/berlin-deal/",
    component: _644d0ebc,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-berlin-deal___ch"
  }, {
    path: "/partner/ameropa/berlin-deal/",
    component: _644d0ebc,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-berlin-deal___at"
  }, {
    path: "/partner/ameropa/berlin-deal/",
    component: _644d0ebc,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-berlin-deal___nl"
  }, {
    path: "/partner/ameropa/berlin-deal/",
    component: _644d0ebc,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-berlin-deal___es"
  }, {
    path: "/partner/ameropa/dresden-deal-2/",
    component: _262e5498,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-dresden-deal-2___de"
  }, {
    path: "/partner/ameropa/dresden-deal-2/",
    component: _262e5498,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-dresden-deal-2___ch"
  }, {
    path: "/partner/ameropa/dresden-deal-2/",
    component: _262e5498,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-dresden-deal-2___at"
  }, {
    path: "/partner/ameropa/dresden-deal-2/",
    component: _262e5498,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-dresden-deal-2___nl"
  }, {
    path: "/partner/ameropa/dresden-deal-2/",
    component: _262e5498,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-dresden-deal-2___es"
  }, {
    path: "/partner/ameropa/dresden-deal/",
    component: _a8ccb79a,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-dresden-deal___de"
  }, {
    path: "/partner/ameropa/dresden-deal/",
    component: _a8ccb79a,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-dresden-deal___ch"
  }, {
    path: "/partner/ameropa/dresden-deal/",
    component: _a8ccb79a,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-dresden-deal___at"
  }, {
    path: "/partner/ameropa/dresden-deal/",
    component: _a8ccb79a,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-dresden-deal___nl"
  }, {
    path: "/partner/ameropa/dresden-deal/",
    component: _a8ccb79a,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-dresden-deal___es"
  }, {
    path: "/partner/ameropa/hamburg-deal-2/",
    component: _4e9d9ba6,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-hamburg-deal-2___de"
  }, {
    path: "/partner/ameropa/hamburg-deal-2/",
    component: _4e9d9ba6,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-hamburg-deal-2___ch"
  }, {
    path: "/partner/ameropa/hamburg-deal-2/",
    component: _4e9d9ba6,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-hamburg-deal-2___at"
  }, {
    path: "/partner/ameropa/hamburg-deal-2/",
    component: _4e9d9ba6,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-hamburg-deal-2___nl"
  }, {
    path: "/partner/ameropa/hamburg-deal-2/",
    component: _4e9d9ba6,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-hamburg-deal-2___es"
  }, {
    path: "/partner/ameropa/hamburg-deal/",
    component: _521e39f0,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-hamburg-deal___de"
  }, {
    path: "/partner/ameropa/hamburg-deal/",
    component: _521e39f0,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-hamburg-deal___ch"
  }, {
    path: "/partner/ameropa/hamburg-deal/",
    component: _521e39f0,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-hamburg-deal___at"
  }, {
    path: "/partner/ameropa/hamburg-deal/",
    component: _521e39f0,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-hamburg-deal___nl"
  }, {
    path: "/partner/ameropa/hamburg-deal/",
    component: _521e39f0,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-hamburg-deal___es"
  }, {
    path: "/partner/ameropa/koeln-deal/",
    component: _fdb8bd7e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-koeln-deal___de"
  }, {
    path: "/partner/ameropa/koeln-deal/",
    component: _fdb8bd7e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-koeln-deal___ch"
  }, {
    path: "/partner/ameropa/koeln-deal/",
    component: _fdb8bd7e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-koeln-deal___at"
  }, {
    path: "/partner/ameropa/koeln-deal/",
    component: _fdb8bd7e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-koeln-deal___nl"
  }, {
    path: "/partner/ameropa/koeln-deal/",
    component: _fdb8bd7e,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-koeln-deal___es"
  }, {
    path: "/partner/ameropa/leipzig-deal/",
    component: _32c72a2c,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-leipzig-deal___de"
  }, {
    path: "/partner/ameropa/leipzig-deal/",
    component: _32c72a2c,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-leipzig-deal___ch"
  }, {
    path: "/partner/ameropa/leipzig-deal/",
    component: _32c72a2c,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-leipzig-deal___at"
  }, {
    path: "/partner/ameropa/leipzig-deal/",
    component: _32c72a2c,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-leipzig-deal___nl"
  }, {
    path: "/partner/ameropa/leipzig-deal/",
    component: _32c72a2c,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-leipzig-deal___es"
  }, {
    path: "/partner/ameropa/muenchen-deal-2/",
    component: _fa36a4f8,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-muenchen-deal-2___de"
  }, {
    path: "/partner/ameropa/muenchen-deal-2/",
    component: _fa36a4f8,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-muenchen-deal-2___ch"
  }, {
    path: "/partner/ameropa/muenchen-deal-2/",
    component: _fa36a4f8,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-muenchen-deal-2___at"
  }, {
    path: "/partner/ameropa/muenchen-deal-2/",
    component: _fa36a4f8,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-muenchen-deal-2___nl"
  }, {
    path: "/partner/ameropa/muenchen-deal-2/",
    component: _fa36a4f8,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-muenchen-deal-2___es"
  }, {
    path: "/partner/ameropa/muenchen-deal/",
    component: _4da9481f,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-muenchen-deal___de"
  }, {
    path: "/partner/ameropa/muenchen-deal/",
    component: _4da9481f,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-muenchen-deal___ch"
  }, {
    path: "/partner/ameropa/muenchen-deal/",
    component: _4da9481f,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-muenchen-deal___at"
  }, {
    path: "/partner/ameropa/muenchen-deal/",
    component: _4da9481f,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-muenchen-deal___nl"
  }, {
    path: "/partner/ameropa/muenchen-deal/",
    component: _4da9481f,
    pathToRegexpOptions: {"strict":true},
    name: "partner-ameropa-muenchen-deal___es"
  }, {
    path: "/",
    component: _482400f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }, {
    path: "/",
    component: _482400f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___ch"
  }, {
    path: "/",
    component: _482400f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___at"
  }, {
    path: "/",
    component: _482400f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/",
    component: _482400f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___es"
  }, {
    path: "/aanbieding/accommodatie/:slug/",
    component: _9cb39f34,
    pathToRegexpOptions: {"strict":true},
    name: "deal-accommodation-slug___nl"
  }, {
    path: "/aanbieding/apt/:slug/",
    component: _ae1892ec,
    pathToRegexpOptions: {"strict":true},
    name: "deal-amadeus-package-tour-slug___nl"
  }, {
    path: "/aanbieding/b/:slug/",
    component: _62fdb242,
    pathToRegexpOptions: {"strict":true},
    name: "deal-basic-slug___nl"
  }, {
    path: "/aanbieding/pt/:slug/",
    component: _1e8981b1,
    pathToRegexpOptions: {"strict":true},
    name: "deal-package-tour-slug___nl"
  }, {
    path: "/aanbieding/tb/:slug/",
    component: _425f0226,
    pathToRegexpOptions: {"strict":true},
    name: "deal-travel-basic-slug___nl"
  }, {
    path: "/aanbieding/tc/:slug/",
    component: _79af4f4e,
    pathToRegexpOptions: {"strict":true},
    name: "deal-travel-combination-slug___nl"
  }, {
    path: "/aanbieding/vluchten/:slug/",
    component: _aa9a86bc,
    pathToRegexpOptions: {"strict":true},
    name: "deal-flight-slug___nl"
  }, {
    path: "/angebot/apt/:slug/",
    component: _ae1892ec,
    pathToRegexpOptions: {"strict":true},
    name: "deal-amadeus-package-tour-slug___de"
  }, {
    path: "/angebot/apt/:slug/",
    component: _ae1892ec,
    pathToRegexpOptions: {"strict":true},
    name: "deal-amadeus-package-tour-slug___ch"
  }, {
    path: "/angebot/apt/:slug/",
    component: _ae1892ec,
    pathToRegexpOptions: {"strict":true},
    name: "deal-amadeus-package-tour-slug___at"
  }, {
    path: "/angebot/b/:slug/",
    component: _62fdb242,
    pathToRegexpOptions: {"strict":true},
    name: "deal-basic-slug___de"
  }, {
    path: "/angebot/b/:slug/",
    component: _62fdb242,
    pathToRegexpOptions: {"strict":true},
    name: "deal-basic-slug___ch"
  }, {
    path: "/angebot/b/:slug/",
    component: _62fdb242,
    pathToRegexpOptions: {"strict":true},
    name: "deal-basic-slug___at"
  }, {
    path: "/angebot/fluege/:slug/",
    component: _aa9a86bc,
    pathToRegexpOptions: {"strict":true},
    name: "deal-flight-slug___de"
  }, {
    path: "/angebot/fluege/:slug/",
    component: _aa9a86bc,
    pathToRegexpOptions: {"strict":true},
    name: "deal-flight-slug___ch"
  }, {
    path: "/angebot/fluege/:slug/",
    component: _aa9a86bc,
    pathToRegexpOptions: {"strict":true},
    name: "deal-flight-slug___at"
  }, {
    path: "/angebot/pt/:slug/",
    component: _1e8981b1,
    pathToRegexpOptions: {"strict":true},
    name: "deal-package-tour-slug___de"
  }, {
    path: "/angebot/pt/:slug/",
    component: _1e8981b1,
    pathToRegexpOptions: {"strict":true},
    name: "deal-package-tour-slug___ch"
  }, {
    path: "/angebot/pt/:slug/",
    component: _1e8981b1,
    pathToRegexpOptions: {"strict":true},
    name: "deal-package-tour-slug___at"
  }, {
    path: "/angebot/tb/:slug/",
    component: _425f0226,
    pathToRegexpOptions: {"strict":true},
    name: "deal-travel-basic-slug___de"
  }, {
    path: "/angebot/tb/:slug/",
    component: _425f0226,
    pathToRegexpOptions: {"strict":true},
    name: "deal-travel-basic-slug___ch"
  }, {
    path: "/angebot/tb/:slug/",
    component: _425f0226,
    pathToRegexpOptions: {"strict":true},
    name: "deal-travel-basic-slug___at"
  }, {
    path: "/angebot/tc/:slug/",
    component: _79af4f4e,
    pathToRegexpOptions: {"strict":true},
    name: "deal-travel-combination-slug___de"
  }, {
    path: "/angebot/tc/:slug/",
    component: _79af4f4e,
    pathToRegexpOptions: {"strict":true},
    name: "deal-travel-combination-slug___ch"
  }, {
    path: "/angebot/tc/:slug/",
    component: _79af4f4e,
    pathToRegexpOptions: {"strict":true},
    name: "deal-travel-combination-slug___at"
  }, {
    path: "/angebot/unterkuenfte/:slug/",
    component: _9cb39f34,
    pathToRegexpOptions: {"strict":true},
    name: "deal-accommodation-slug___de"
  }, {
    path: "/angebot/unterkuenfte/:slug/",
    component: _9cb39f34,
    pathToRegexpOptions: {"strict":true},
    name: "deal-accommodation-slug___ch"
  }, {
    path: "/angebot/unterkuenfte/:slug/",
    component: _9cb39f34,
    pathToRegexpOptions: {"strict":true},
    name: "deal-accommodation-slug___at"
  }, {
    path: "/deal/alojamientos/:slug/",
    component: _9cb39f34,
    pathToRegexpOptions: {"strict":true},
    name: "deal-accommodation-slug___es"
  }, {
    path: "/deal/apt/:slug/",
    component: _ae1892ec,
    pathToRegexpOptions: {"strict":true},
    name: "deal-amadeus-package-tour-slug___es"
  }, {
    path: "/deal/b/:slug/",
    component: _62fdb242,
    pathToRegexpOptions: {"strict":true},
    name: "deal-basic-slug___es"
  }, {
    path: "/deal/pt/:slug/",
    component: _1e8981b1,
    pathToRegexpOptions: {"strict":true},
    name: "deal-package-tour-slug___es"
  }, {
    path: "/deal/tb/:slug/",
    component: _425f0226,
    pathToRegexpOptions: {"strict":true},
    name: "deal-travel-basic-slug___es"
  }, {
    path: "/deal/tc/:slug/",
    component: _79af4f4e,
    pathToRegexpOptions: {"strict":true},
    name: "deal-travel-combination-slug___es"
  }, {
    path: "/deal/vuelos/:slug/",
    component: _aa9a86bc,
    pathToRegexpOptions: {"strict":true},
    name: "deal-flight-slug___es"
  }, {
    path: "/tag/:slug/",
    component: _958c87ee,
    pathToRegexpOptions: {"strict":true},
    name: "tag-slug___de"
  }, {
    path: "/tag/:slug/",
    component: _958c87ee,
    pathToRegexpOptions: {"strict":true},
    name: "tag-slug___ch"
  }, {
    path: "/tag/:slug/",
    component: _958c87ee,
    pathToRegexpOptions: {"strict":true},
    name: "tag-slug___at"
  }, {
    path: "/tag/:slug/",
    component: _958c87ee,
    pathToRegexpOptions: {"strict":true},
    name: "tag-slug___nl"
  }, {
    path: "/tag/:slug/",
    component: _958c87ee,
    pathToRegexpOptions: {"strict":true},
    name: "tag-slug___es"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
